import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import logoPj from "../assets/images/logo-pj-notxt.jpg";
import BasePages from "./BasePages";

const CityContent = ({title, contentType}) => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const navigate = useNavigate();

    const renderContent = () => {
        if (contentType === "actuality") {
            return (
                <>
                    {!isEmpty(contentCurrentCity.cityNewsLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.cityNewsLink,
                                    title: "Actualités de la ville",
                                    headerTitle: "Retourner aux actualités",
                                    headerRedirect: "les-actualites"
                                }
                            })}>
                                <img id="logocity" className="logo-actualites" src={
                                    "https://api.lesbonsplans.pro" +
                                    "/upload/upload/image/" +
                                    currentCity.logo
                                } alt="Actualités" width={250}></img>Actualités de la ville
                            </button>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.newspapers) && (
                        contentCurrentCity.newspapers.map((newspaper, index) => (
                            <li key={index}>
                                <div className="newspaper">
                                    <button onClick={() => window.open(newspaper.url)}>
                                        <img className="logo-actualites" src={
                                            "https://api.lesbonsplans.pro" +
                                            "/upload/upload/image/" +
                                            newspaper.image
                                        } alt="Journaux" width={250}></img>
                                    </button>
                                </div>
                            </li>
                        ))
                    )}
                </>
            );
        } else if (contentType === "guide") {
            return (
                <>
                    <li>
                        <NavLink to="/index-rues">
                            <i className="bx bxs-directions"></i>Plan/Index des rues
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/pages-jaunes">
                            <img className="logo-pages-jaunes" src={logoPj} alt="Logo Pages Jaunes" width={100}/>
                            Pages jaunes
                        </NavLink>
                    </li>
                    {!isEmpty(contentCurrentCity.weatherLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.weatherLink,
                                    title: "La météo"
                                }
                            })}>
                                <i className="bx bxs-cloud"></i>La météo
                            </button>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.infoCityLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.infoCityLink,
                                    title: "Infos pratiques"
                                }
                            })}>
                                <i className="bx bxs-info-circle"></i>Infos pratiques
                            </button>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.liveCamLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.liveCamLink,
                                    title: "La ville en direct"
                                }
                            })}>
                                <i className="bx bxs-video"></i>La ville en direct
                            </button>
                        </li>
                    )}
                    {(!isEmpty(contentCurrentCity.cityMagLink) || !isEmpty(contentCurrentCity.magazines)) && (
                        <li>
                            <NavLink to="/magazines">
                                <i className="bx bx-book"></i>Magazines
                            </NavLink>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.guidedTour) && (
                        <li>
                            <NavLink to="/visites-guidees">
                                <i className="bx bxs-user-voice"></i>Visites guidées
                            </NavLink>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.interactCarteLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.interactCarteLink,
                                    title: "Carte interactive"
                                }
                            })}>
                                <i className="bx bxs-map"></i>Carte interactive
                            </button>
                        </li>
                    )}
                    {!isEmpty(contentCurrentCity.someNumberLink) && (
                        <li>
                            <button onClick={() => navigate("/iframe-link", {
                                state: {
                                    Link: contentCurrentCity.someNumberLink,
                                    title: "Quelques Chiffres"
                                }
                            })}>
                                <i className="bx bxs-report"></i>Quelques Chiffres
                            </button>
                        </li>
                    )}
                </>
            );
        } else if (contentType === "social-media") {
            return (
                <>
                    {!isEmpty(currentCity.socialMedia) && (
                        <li>
                            <NavLink to="/reseaux-sociaux">
                                <i className="bx bxs-chat"></i>Réseaux sociaux
                            </NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink to="/contact">
                            <i className="bx bxs-contact"></i>Contact
                        </NavLink>
                    </li>
                </>
            )
        } else if (contentType === "music") {
            return (
                <>
                    <li>
                        <NavLink to="/albums" state={{ action: 'listen' }}>
                            <i className="bx bx-play-circle"></i>Écouter nos musiques
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/albums" state={{ action: 'buy' }}>
                            <i className="bx bxs-cart-add"></i>Acheter nos musiques
                        </NavLink>
                    </li>
                </>
            )
        }
    };

    return (
        <BasePages>
            <section className="city-content">
                <h1>{title}</h1>
                <div className="nav-guides">
                    <ul>
                        {renderContent()}
                    </ul>
                </div>
            </section>
        </BasePages>
    );
};

export default CityContent;