import React, {useRef, useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";
import BasePages from "./BasePages";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause} from '@fortawesome/free-solid-svg-icons';
import {useSelector, useDispatch} from "react-redux";
import {addToCart, removeFromCart} from "../actions/cart.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import {downloadAlbum} from "../actions/album.action";
import PlayerBar from "../components/PlayerBar";

const Album = () => {
    const location = useLocation();
    const {album, action} = location.state;
    const userConnect = useSelector((state) => state.authReducer);
    const dataCart = useSelector((state) => state.cartReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const audioRefs = useRef({});
    const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
    const [durations, setDurations] = useState({});

    const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);


    useEffect(() => {
        if (album && album.content) {
            album.content.forEach((audio) => {
                const audioElement = new Audio(`https://api.lesbonsplans.pro/upload/upload/audio/${audio.song}`);

                audioElement.addEventListener('error', (e) => {
                    console.error(`Erreur de chargement de l'audio ${audio._id}:`, e);
                    setDurations((prevDurations) => ({
                        ...prevDurations,
                        [audio._id]: null,
                    }));
                });

                audioElement.addEventListener('loadedmetadata', () => {
                    if (audioElement.duration !== Infinity && !isNaN(audioElement.duration)) {
                        setDurations((prevDurations) => ({
                            ...prevDurations,
                            [audio._id]: audioElement.duration,
                        }));
                    } else {
                        console.warn(`Durée non disponible pour l'audio ${audio._id}`);
                        setDurations((prevDurations) => ({
                            ...prevDurations,
                            [audio._id]: null,
                        }));
                    }
                });

                audioElement.load();
            });
        }
    }, [album]);

    useEffect(() => {
        if (isPlaying && action === 'listen') {
            document.body.classList.add('player-bar-open');
        } else {
            document.body.classList.remove('player-bar-open');
        }
    }, [isPlaying]);


    const playAudioAtIndex = (index) => {
        const audio = album.content[index];
        const ref = audioRefs.current[audio._id];
        if (currentlyPlaying && currentlyPlaying !== audio._id) {
            const previousAudio = audioRefs.current[currentlyPlaying];
            previousAudio.pause();
            previousAudio.currentTime = 0;
        }
        ref.play();
        setCurrentlyPlaying(audio._id);
        setCurrentAudioIndex(index);
        setIsPlaying(true);
    };

    const handleGlobalPlayPause = () => {
        if (currentAudioIndex === null) return;
        const current = album.content[currentAudioIndex];
        const ref = audioRefs.current[current._id];
        if (ref.paused) {
            ref.play();
            setIsPlaying(true);
        } else {
            ref.pause();
            setIsPlaying(false);
        }
    };

    const handlePrev = () => {
        if (currentAudioIndex > 0) {
            playAudioAtIndex(currentAudioIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentAudioIndex < album.content.length - 1) {
            playAudioAtIndex(currentAudioIndex + 1);
        }
    };


    const handlePlayPause = (audioId) => {
        const index = album.content.findIndex(a => a._id === audioId);
        if (index !== -1) {
            playAudioAtIndex(index);
        }
    };

    const handleTimeUpdate = (audioId, timePreview) => {
        const audio = audioRefs.current[audioId];
        if (audio.currentTime >= timePreview) {
            audio.pause();
            audio.currentTime = 0;
            setCurrentlyPlaying(null);
        }
    };

    const formatTime = (seconds) => {
        if (seconds === null || isNaN(seconds) || seconds === Infinity) {
            return 'N/a';
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleAddToCart = (audio) => {
        const isAlreadyInCart = dataCart.items.some(item => item._id === audio._id);

        if (isAlreadyInCart) {
            dispatch(showFlashMessage('Cet article est déjà dans le panier', 'orange', 3000));
        } else {
            dispatch(addToCart(audio));
            dispatch(showFlashMessage('Article ajouté au panier', 'green', 3000));
        }
    };


    const price = userConnect.user
        ? (userConnect.user.userLevels[0] !== 5 ? album.cost.particulier : album.cost.pro)
        : (localStorage.getItem('selectedTypeUser') === "isUser" ? album.cost.particulier : album.cost.pro);


    const navigateToCart = async () => {
        const cart = JSON.parse(localStorage.getItem("cart")) || {items: []};
        const items = cart.items || [];
        const albums = items.filter(item => item.name);
        const removedTitles = [];

        albums.forEach(album => {
            album.content.forEach(audio => {
                const index = items.findIndex(item => item._id === audio._id);
                if (index !== -1) {
                    removedTitles.push(audio.title);
                    items.splice(index, 1);
                    dispatch(removeFromCart(audio));
                }
            });
        });

        await localStorage.setItem("cart", JSON.stringify({items}));
        navigate('/panier', {state: {removedTitles}});
    }

    const purchaseUser = (album_id) => {
        if (userConnect.user) {
            return userConnect.user.listPurchase.some(purchase =>
                purchase.products.some(product => product.id === album_id)
            );
        }
    };

    const handleDownloadAlbum = () => {
        try {
            dispatch(downloadAlbum(userConnect.user._id));
            dispatch(showFlashMessage('Téléchargement de l\'album réussi', 'green', 3000));
        } catch (error) {
            dispatch(showFlashMessage('Erreur lors du téléchargement de l\'album', 'red', 3000));
        }
    };


    return (
        <BasePages navPath="albums" stringBtn="Retourner aux musiques" state={{action}}>
            <section className={`album ${action === 'buy' ? 'player-bar-open-buy' : action === 'listen' ? 'player-bar-open-listen' : ''}`}>
                <img className="logoGroup" src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoGroupe}`}
                     alt="album"/>
                <div className="album-content-text">
                    <p>{album.description}</p>
                </div>
                <img src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoAlbum}`} alt={album.name}/>
                <section className="album-content-audio">
                    <h2>Audio</h2>
                    <div className="list-audio">
                        <div className="audio-content">
                            <p>Album complet ({album.content.length} morceaux)</p>
                            <p className="audio-content-data"><span>Durée totale:</span>
                                {formatTime(Object.values(durations).reduce((total, duration) => total + (duration || 0), 0))}
                            </p>
                            {action === 'buy' && (
                                <>
                                    <p className="price"><span>Prix:</span>{price}€</p>
                                    <div className="button-album">
                                        {purchaseUser(album._id) ? (
                                            <button className="bx bxs-download" onClick={() => handleDownloadAlbum()}>
                                            </button>
                                        ) : (
                                            <button onClick={() => handleAddToCart(album)}>
                                                <i className='bx bxs-cart-add custom-cart-icon'></i>
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            {action === 'listen' && (
                                <div className="button-album">
                                    <button
                                        onClick={() => playAudioAtIndex(0)}
                                        data-playing={currentlyPlaying === album.content[0]._id}
                                    >
                                        {currentlyPlaying === album.content[0]._id ? (
                                            <FontAwesomeIcon icon={faPause}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faPlay}/>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                        {album.content.map((audio) => (
                            <div className="audio-content" key={audio._id}>
                                <p>{audio.title}</p>
                                <p className="audio-content-data"><span>Durée:</span>
                                    {formatTime(durations[audio._id])}</p>
                                {action === 'buy' && (
                                    <>
                                        <p className="audio-content-data">
                                            <span>Prix:</span> {audio.cost.toFixed(2)}€
                                        </p>
                                        <div className="timepreview">
                                            <p className="text-demo">
                                                Demo <span>:</span>
                                            </p>
                                            <p>{formatTime(audio.timePreview)}</p>
                                        </div>
                                    </>
                                )}
                                <div className="button-album">
                                    <button
                                        onClick={() => handlePlayPause(audio._id)}
                                        data-playing={currentlyPlaying === audio._id}
                                    >
                                        {currentlyPlaying === audio._id ? (
                                            <FontAwesomeIcon icon={faPause}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faPlay}/>
                                        )}
                                    </button>
                                    {action === 'buy' ? (
                                        <audio
                                            ref={(el) => (audioRefs.current[audio._id] = el)}
                                            src={`https://api.lesbonsplans.pro/upload/upload/audio/${audio.song}`}
                                            onTimeUpdate={() => handleTimeUpdate(audio._id, audio.timePreview)}
                                            onEnded={() => setCurrentlyPlaying(null)}
                                        />
                                    ) : (
                                        <audio
                                            ref={(el) => (audioRefs.current[audio._id] = el)}
                                            src={`https://api.lesbonsplans.pro/upload/upload/audio/${audio.song}`}
                                            onEnded={() => {
                                                setCurrentlyPlaying(null);
                                                handleNext();
                                            }}/>
                                    )}

                                    {action === 'buy' && !purchaseUser(album._id) && (
                                        <>
                                            {!purchaseUser(audio.song) ? (
                                                <>
                                                    {(userConnect.user && userConnect.user.userLevels[0] !== 5) ||
                                                    localStorage.getItem('selectedTypeUser') === "isUser" ? (
                                                        <button onClick={() => handleAddToCart(audio)}>
                                                            <i className='bx bxs-cart-add custom-cart-icon'></i>
                                                        </button>
                                                    ) : null}
                                                </>
                                            ) : (<button className="bx bxs-download"
                                                         onClick={() => handleDownloadAlbum()}>
                                            </button>)}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="album-content-karaok"></section>
                <section className="album-content-video"></section>
                {action === 'buy' && (
                    <div className="album-download">
                        <button className="button-download" onClick={navigateToCart}>Voir la panier</button>
                    </div>
                )}
                <PlayerBar
                    currentAudio={album.content[currentAudioIndex]}
                    isPlaying={isPlaying}
                    onPlayPause={handleGlobalPlayPause}
                    onPrev={handlePrev}
                    onNext={handleNext}
                />

            </section>
        </BasePages>
    );
}
export default Album;
