import React, {useEffect, useState} from 'react';
import GenericSelect from "../components/GenericSelect";
import {isEmpty} from "../assets/Utils";
import {useDispatch, useSelector} from "react-redux";
import {pictureCompressor} from "../assets/utils/pictureCompressor";
import {
    deleteElement,
    resetReducer,
    setLogoPartner,
    setPicturePartner,
    setVideoPartner
} from "../actions/partnerUploads.action";
import AdminSortingPartnerUploads from "../components/AdminSortingPartnerUploads";
import {setMessage} from "../actions/message.action";

const AdminNewMessage = () => {
    const dataCity = useSelector((state) => state.cityReducer);
    const [valueCitySelector, setValueCitySelector] = useState([]);
    const [links, setLinks] = useState(['']);
    const contentNewMessage = useSelector((state) => state.partnerUploadsReducer);
    const [logoNewMessage, setLogoNewMessage] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(contentNewMessage.logo)) {
            setLogoNewMessage(contentNewMessage.logo);
        }
    }, [contentNewMessage.logo]);

    useEffect(() => {
        const form = document.getElementById("form-new-message");
        const handleFormChange = () => {
            if (form.checkValidity() && !isEmpty(valueCitySelector)) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        };

        form.addEventListener('input', handleFormChange);
        return () => {
            form.removeEventListener('input', handleFormChange);
        };
    }, [valueCitySelector]);

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataLogo = await pictureCompressor(file);
        if (!isEmpty(logoNewMessage)) {
            dispatch(await deleteElement(logoNewMessage));
            dispatch(await setLogoPartner(formDataLogo));
            return;
        }
        dispatch(await setLogoPartner(formDataLogo));
    };

    const handlePictureUpload = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            let formDataPicture = await pictureCompressor(files[i]);
            dispatch(await setPicturePartner(formDataPicture));
        }
    };

    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        let formDataVideo = new FormData();
        formDataVideo.append("file", file);
        dispatch(await setVideoPartner(formDataVideo));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid) return;
        const form = e.target;
        const formData = new FormData(form);
        let uploadsNewMessage = contentNewMessage.uploads;
        let contentCityId = valueCitySelector.map(city => getContentCityByCityName(city.value));
        let newMessage = {
            title: formData.get("title"),
            content: formData.get("description"),
            logo: contentNewMessage.logo,
            city: valueCitySelector.map(city => city.value),
            url: links,
            contentUploads: uploadsNewMessage,
            datePublication: formData.get("datePublication"),
        };
        await dispatch(setMessage(newMessage, contentCityId));
        setValueCitySelector([]);
        setLinks(['']);
        dispatch(resetReducer());
        form.reset();
        setIsFormValid(false);
    };

    const handleChangeCitySelector = (value) => {
        setValueCitySelector(value);
    };

    const handleAddLink = () => {
        setLinks([...links, '']);
    };

    const handleLinkChange = (index, event) => {
        const newLinks = [...links];
        newLinks[index] = event.target.value;
        setLinks(newLinks);
    };

    const getContentCityByCityName = (cityName) => {
        const city = dataCity.find((city) => city.name === cityName);
        return city.contentCity;
    };

    const transformCityOptions = (data) => {
        return data.map((city) => ({label: city.name, value: city.name}));
    };

    return (
        <section className="new-message">
            <h2>Création d'un nouveau message</h2>
            <form id="form-new-message" autoComplete="off" onSubmit={handleSubmit}>
                <section className="new-message-setup">
                    <div className="info-new-message">
                        <h3>Informations</h3>
                        <label htmlFor="title">Titre : </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Titre du message"
                            required
                        />
                        <label htmlFor="datePublication">Date et heure de publication : </label>
                        <input
                            type="datetime-local"
                            id="datePublication"
                            name="datePublication"
                            required
                        />
                    </div>
                    <div className="city-new-message">
                        <h3>Ville</h3>
                        <GenericSelect
                            onChange={handleChangeCitySelector}
                            value={valueCitySelector}
                            selector="cityReducer"
                            optionsTransformer={transformCityOptions}
                            placeholder="Sélectionnez une ou plusieurs villes"
                            isMulti={true}
                            enableSelectAll={true}
                        />
                    </div>
                    <div className="links-section">
                        <h3>Liens Url</h3>
                        {links.map((link, index) => (
                            <div key={index} className="link-input">
                                <label htmlFor={`lien-${index}`}>Lien {index + 1} : </label>
                                <input
                                    type="text"
                                    id={`lien-${index}`}
                                    placeholder="Ajouter une url"
                                    value={link}
                                    pattern="https?://.+"
                                    onChange={(e) => handleLinkChange(index, e)}
                                />
                            </div>
                        ))}
                        <button type="button" onClick={handleAddLink}>+</button>
                    </div>
                </section>
                <div className="description-new-message">
                    <label htmlFor="description" id="label-description">
                        Rédaction du message :
                    </label>
                    <textarea id="description" name="description" placeholder="Rédigez le corps du message" required/>
                </div>
                <section className="new-message-content">
                    <div className="new-message-content-container">
                        <label htmlFor="logo-content">
                            {isEmpty(contentNewMessage.logo)
                                ? "Ajouter un logo"
                                : "Modifier le logo"}
                        </label>
                        <input
                            type="file"
                            id="logo-content"
                            onChange={handleLogoUpload}
                            hidden
                            required
                        />
                        <label htmlFor="picture-content">Ajouter une photo</label>
                        <input
                            type="file"
                            id="picture-content"
                            onChange={handlePictureUpload}
                            hidden
                            multiple
                        />
                        <label htmlFor="video-content">Ajouter une vidéo</label>
                        <input
                            type="file"
                            id="video-content"
                            onChange={handleVideoUpload}
                            hidden
                        />
                    </div>
                </section>
                {!isEmpty(contentNewMessage.logo) && (
                    <section className="uploaded-logo">
                        <h3>Logo ajouté</h3>
                        <img
                            src={
                                "https://api.lesbonsplans.pro" +
                                "/upload/partner/image/" +
                                contentNewMessage.logo
                            }
                            alt="Logo ajouté"
                            width={200}
                        />
                    </section>
                )}
                {!isEmpty(contentNewMessage.uploads) && (
                    <AdminSortingPartnerUploads/>
                )}
                <input
                    id={isFormValid ? "valid" : "invalid"}
                    type="submit"
                    value="Créer le message"
                />
            </form>
        </section>
    );
};

export default AdminNewMessage;