import React, {useState} from "react";
import {handleUserConnection, login} from "../actions/auth.action";
import store from "..";
import {getUser, getUserById} from "../actions/user.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import {useDispatch} from "react-redux";
import BasePagesBackoffice from "./BasePagesBackoffice";
import {NavLink} from "react-router-dom";


const Connexion = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await store.dispatch(handleUserConnection(email, password));
            const authResponse = store.getState().authReducer.auth;

            if (authResponse.response) {
                const userId = authResponse.id;
                await store.dispatch(getUserById(userId));
                const user = store.getState().userReducer;
                store.dispatch(login(user));
                store.dispatch(getUser());
                localStorage.removeItem('selectedTypeUser');
                dispatch(showFlashMessage(
                    "Connexion réussie!",
                    "green",
                    3000,
                    "/administration/accueil"
                ));
                return;
            } else {
                if (authResponse.field === "email") {
                    dispatch(showFlashMessage("Aucun compte trouvé avec cet email", "red"));
                } else if (authResponse.field === "password") {
                    dispatch(showFlashMessage("Le mot de passe est incorrect", "red"));
                } else if (authResponse.field === "verified") {
                    dispatch(showFlashMessage("Votre compte n'est pas encore vérifié. Veuillez vérifier vos emails.", "red"));
                } else {
                    dispatch(showFlashMessage("Erreur lors de la connexion. Veuillez réessayer.", "red"));
                }
            }
        } catch (error) {
            dispatch(showFlashMessage(
                "Une erreur est survenue lors de la connexion",
                "red"
            ));
        }
    };

    return (
        <BasePagesBackoffice navBarAdmin={false}>
            <section className="connexion">
                <h2>Connexion</h2>
                <form onSubmit={(e) => handleFormSubmit(e)}
                      className="form-connexion">
                    <input
                        type="email"
                        placeholder="Entrez votre adresse-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Entrez votre mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="submit"
                        value="Se connecter"
                        disabled={!email || !password}
                        id={email && password ? "valid" : "invalid"}

                    />
                </form>
                <div className="form-links">
                    <NavLink to="/mot-de-passe-oublie">Mot de passe oublié ?</NavLink>
                    <NavLink to="/creation-du-compte">Créer un compte</NavLink>
                </div>
            </section>
        </BasePagesBackoffice>
    );
};

export default Connexion;