import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import AdminNewCity from "../layouts/AdminNewCity";
import PopupCitySocialMedia from "../layouts/PopupCitySocialMedia";
import AdminUpdateCity from "../layouts/AdminUpdateCity";
import {updateCity, deleteCity, getCity} from "../actions/city.action";
import AdminTable from "../components/AdminTable";
import BasePagesBackoffice from "./BasePagesBackoffice";
import AdminFilterForm from "../components/AdminFilterForm";

const AdminCities = () => {
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const dataPartner = useSelector((state) => state.partnerReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const [popupCitySocialMedia, setPopupCitySocialMedia] = useState({});
    const [popupCityUpdate, setPopupCityUpdate] = useState({});
    const [nameCitySorting, setNameCitySorting] = useState("");
    const [countryCitySorting, setCountryCitySorting] = useState([]);
    const [countryCityId, setCountryCityId] = useState("");
    const [postalCodeCitySorting, setPostalCodeCitySorting] = useState("");
    const [filteredCities, setFilteredCities] = useState([]);
    const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);

    const dispatch = useDispatch();

    const handleActivateCity = (e, city) => {
        const updatedCity = {...city, isActive: !city.isActive};
        dispatch(updateCity(updatedCity));
    };

    const partnerInTheCity = (city) => {
        try {
            return dataPartner
                .filter((partner) => partner.city === city._id)
                .map((partner) => partner);
        } catch (error) {
            console.error("Erreur lors de la récupération des partenaires :", error);
            return [];
        }
    };

    const handleDeleteClick = (city) => {
        const partner = partnerInTheCity(city);
        const contentCity = getContentCityById(city.contentCity);
        dispatch(deleteCity(city, partner, contentCity));
    };

    const getContentCityById = (contentCityId) => {
        if (Array.isArray(dataContentCity)) {
            return dataContentCity.find((contentCity) => contentCity._id === contentCityId);
        } else {
            console.error("dataContentCity is not an array");
            return null;
        }
    };

    const handleCountrySelected = (value) => {
        const normalizedValue = Array.isArray(value) ? value : value ? [value] : [];
        setCountryCitySorting(normalizedValue);
    };

    useEffect(() => {
        if (!isEmpty(countryCitySorting)) {
            let tempValues = [];
            countryCitySorting.forEach((country) => {
                tempValues.push(country.value);
            });
            setCountryCityId(tempValues);
        } else {
            setCountryCityId("");
        }
    }, [countryCitySorting]);

    useEffect(() => {
        const normalizeString = (string) => {
            return string
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
        };

        const tempFilteredCities = dataCity
            .filter((city) =>
                normalizeString(city.name).includes(normalizeString(nameCitySorting))
            )
            .filter(
                (city) =>
                    isEmpty(countryCitySorting) || countryCityId.includes(city.country)
            )
            .filter((city) =>
                normalizeString(city.postalCode).includes(normalizeString(postalCodeCitySorting))
            )
            .sort((a, b) => a.name.localeCompare(b.name));

        if (isEmpty(tempFilteredCities)) {
            setIsFilteredEmpty(true);
        } else {
            setIsFilteredEmpty(false);
        }
        setFilteredCities(tempFilteredCities);
    }, [dataCity, nameCitySorting, countryCityId, postalCodeCitySorting]);

    const columns = [
        {header: "Pays", render: (city) => city.country},
        {header: "Nom", render: (city) => city.name},
        {header: "Code Postal", render: (city) => city.postalCode},
        {header: "Acronyme", render: (city) => city.acronym},
        {
            header: "Image d'arrière plan",
            render: (city) => (
                <img
                    className="city-background-image"
                    src={`https://api.lesbonsplans.pro/upload/city/image/${city.picture}`}
                    alt={"Image d'arrière plan " + city.name}
                />
            )
        },
        {
            header: "Logo",
            render: (city) => (
                <img
                    className="city-logo"
                    src={`https://api.lesbonsplans.pro/upload/city/image/${city.logo}`}
                    alt={"Logo " + city.name}
                />
            )
        },
        {
            header: "Réseaux sociaux",
            render: (city) =>
                city._id ? (
                    <button onClick={() => setPopupCitySocialMedia(city)}>
                        Réseaux sociaux
                    </button>
                ) : (
                    <button disabled>Actualisez pour voir</button>
                )
        },
        {header: "Actif", render: (city) => (city.isActive ? "Oui" : "Non")},
        {
            header: "Activation",
            render: (city) =>
                city._id ? (
                    <button onClick={(e) => handleActivateCity(e, city)}>
                        {city.isActive ? "Désactiver" : "Activer"}
                    </button>
                ) : (
                    <button disabled>Actualisez pour Activer/Désactiver</button>
                )
        },
        {
            header: "Modifications",
            render: (city) =>
                city._id ? (
                    <button onClick={() => setPopupCityUpdate(city)}>
                        Modifier
                    </button>
                ) : (
                    <button disabled>Actualisez pour Modifier</button>
                )
        },
        {
            header: "Suppression",
            render: (city) =>
                city._id ? (
                    <button onClick={() => handleDeleteClick(city)}>
                        Supprimer
                    </button>
                ) : (
                    <button disabled>Actualisez pour Supprimer</button>
                )
        }
    ];

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-cities">
                <AdminNewCity/>
                <section className="city-management">
                    <h2>Gestion des villes</h2>
                    <AdminFilterForm
                        filterConfig={{
                            nameValue: nameCitySorting,
                            countryValue: countryCitySorting,
                            postalCodeValue: postalCodeCitySorting,
                            showCountryFilter: true,
                            showPostalCodeFilter: true,
                            namePlaceholder: "Nom de la ville",
                            nameLabel: "Nom de la ville :",
                        }}
                        onNameChange={setNameCitySorting}
                        onCountryChange={handleCountrySelected}
                        onPostalCodeChange={setPostalCodeCitySorting}
                    />
                    {isFilteredEmpty ? (
                        <p>
                            Aucune ville ne correspond à votre recherche. Veuillez réessayer.
                        </p>
                    ) : (
                        <AdminTable
                            columns={columns}
                            data={filteredCities}
                            onDelete={handleDeleteClick}
                            itemName="cette ville"
                            onRefresh={getCity()}
                        />
                    )}
                </section>
                {!isEmpty(popupCitySocialMedia) && (
                    <PopupCitySocialMedia
                        city={popupCitySocialMedia}
                        closePopup={setPopupCitySocialMedia}
                    />
                )}
                {!isEmpty(popupCityUpdate) && (
                    <AdminUpdateCity
                        city={popupCityUpdate}
                        closePopup={setPopupCityUpdate}
                    />
                )}
            </section>
        </BasePagesBackoffice>
    );
};

export default AdminCities;