import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {forgotPassword} from "../actions/user.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import BasePagesBackoffice from "./BasePagesBackoffice";

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await dispatch(forgotPassword(email));

            if (response.payload && response.payload.success) {
                dispatch(showFlashMessage(
                    "Un email de réinitialisation a été envoyé à votre adresse email.",
                    "green",
                    5000,
                    "/connexion"
                ));
            } else {
                dispatch(showFlashMessage(
                    "Aucun compte trouvé avec cette adresse email.",
                    "red",
                    5000
                ));
            }
        } catch (error) {
            console.error("Error sending reset email:", error);
            dispatch(showFlashMessage(
                "Une erreur est survenue lors de l'envoi de l'email de réinitialisation.",
                "red",
                5000
            ));
        }
    };

    return (
        <BasePagesBackoffice navPath={"connexion"} stringBtn={"Retourner à la page de connexion"} navBarAdmin={false}>
            <section className="forget-password">
                <h2>Mot de passe oublié</h2>
                <form onSubmit={(e) => handleFormSubmit(e)}
                      className="form-forget-password">
                    <input
                        type="email"
                        placeholder="Entrez votre adresse-email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <input
                        type="submit"
                        value="Envoyer un email de réinitialisation"
                        disabled={!email}
                        id={email ? "valid" : "invalid"}
                    />
                </form>
            </section>
        </BasePagesBackoffice>
    );
};

export default ForgetPassword;