import React, { useState } from "react";
import BasePagesBackoffice from "./BasePagesBackoffice";
import AdminTable from "../components/AdminTable";
import AdminFilterForm from "../components/AdminFilterForm";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { getUser } from "../actions/user.action";

const AdminInvoice = () => {
    const dataUser = useSelector((state) => state.userReducer);
    const [selectedImage, setSelectedImage] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [filterLastName, setFilterLastName] = useState("");
    const [filterPostalCode, setFilterPostalCode] = useState("");
    const [filterCountry, setFilterCountry] = useState([]);
    const [filterMinPrice, setFilterMinPrice] = useState("");
    const [filterMaxPrice, setFilterMaxPrice] = useState("");

    const handleCountrySelected = (value) => {
        const normalizedValue = Array.isArray(value) ? value : value ? [value] : [];
        setFilterCountry(normalizedValue);
    };

    const handleImageClick = (src) => {
        setSelectedImage(src);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const handleDownload = (url, filename = "facture.png") => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
            })
            .catch((error) => console.error("Erreur lors du téléchargement:", error));
    };

    const columns = [
        {header: "Date de facture", render: (item) => format(new Date(item.date), "dd/MM/yyyy / HH:mm:ss")},
        {header: "Nom de famille", render: (item) => item.lastName},
        {header: "CP", render: (item) => item.codePostal},
        {header: "Pays", render: (item) => item.country},
        {
            header: "Voir la Facture",
            render: (item) => (
                <img
                    src={`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`}
                    alt={"Logo " + item._id}
                    onClick={() =>
                        handleImageClick(`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`)
                    }
                />
            ),
        },
        {
            header: "Facture",
            render: () => (
                <button
                    onClick={() =>
                        handleDownload(`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`)
                    }
                    className="bx bxs-download"
                >
                </button>
            ),
        },
        {
            header: "Musiques",
            render: () => (
                <button
                    onClick={() =>
                        handleDownload(`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`)
                    }
                >
                    Télécharger
                </button>
            ),
        },
        {header: "Montant TTC", render: (item) => item.totalPrice}, // Nouvelle colonne pour le prix total
    ];

    const getAllPurchases = () => {
        const allPurchases = [];
        dataUser.forEach((user) => {
            user.listPurchase.forEach((purchase) => {
                allPurchases.push({
                    userId: user._id,
                    lastName: user.lastName,
                    codePostal: user.codePostal,
                    country: user.country,
                    date: purchase.date,
                    totalPrice: purchase.totalPrice, // Ajout du prix total
                    products: purchase.products.map((product) => ({
                        type: product.type,
                        id: product._id,
                    })),
                });
            });
        });

        return allPurchases;
    };

    const applyFilters = (data) => {
        return data.filter((item) => {
            const isWithinDateRange =
                (!filterStartDate || new Date(item.date) >= new Date(filterStartDate)) &&
                (!filterEndDate || new Date(item.date) <= new Date(filterEndDate));
            const matchesLastName =
                !filterLastName || item.lastName.toLowerCase().includes(filterLastName.toLowerCase());
            const matchesPostalCode = !filterPostalCode || item.codePostal.includes(filterPostalCode);
            const matchesCountry =
                filterCountry.length === 0 ||
                filterCountry.some(countryObj =>
                    item.country &&
                    item.country.toLowerCase().includes(countryObj.value.toLowerCase())
                );
            const matchesPrice =
                (!filterMinPrice || item.totalPrice >= parseFloat(filterMinPrice)) &&
                (!filterMaxPrice || item.totalPrice <= parseFloat(filterMaxPrice)); // Filtrage par prix

            return isWithinDateRange && matchesLastName && matchesPostalCode && matchesCountry && matchesPrice;
        });
    };

    const purchasesData = applyFilters(getAllPurchases());

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-invoice">
                <h2>Gestion des factures</h2>

                <AdminFilterForm
                    filterConfig={{
                        nameValue: filterLastName,
                        postalCodeValue: filterPostalCode,
                        countryValue: filterCountry,
                        startDateValue: filterStartDate,
                        endDateValue: filterEndDate,
                        minPriceValue: filterMinPrice,
                        maxPriceValue: filterMaxPrice,
                        showNameFilter: true,
                        showPostalCodeFilter: true,
                        showCountryFilter: true,
                        showDateFilter: true,
                        showPriceFilter: true,
                        namePlaceholder: "Nom de famille",
                        nameLabel: "Nom de famille :",
                    }}
                    onNameChange={setFilterLastName}
                    onPostalCodeChange={setFilterPostalCode}
                    onCountryChange={handleCountrySelected}
                    onDateChange={(type, value) =>
                        type === "start" ? setFilterStartDate(value) : setFilterEndDate(value)
                    }
                    onPriceChange={(type, value) =>
                        type === "min" ? setFilterMinPrice(value) : setFilterMaxPrice(value)
                    }
                />

                <AdminTable
                    columns={columns}
                    data={purchasesData}
                    onDelete={() => console.log("Delete click")}
                    itemName="cet album"
                    onRefresh={getUser()}
                />
                {selectedImage && (
                    <div className="modal-bigger-picture" onClick={handleCloseModal}>
                        <div className="modal-content">
                            <img src={selectedImage} alt="Facture"/>
                        </div>
                    </div>
                )}
            </section>
        </BasePagesBackoffice>
    );
};

export default AdminInvoice;