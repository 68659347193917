import React from "react";
import GenericSelect from "./GenericSelect";

const AdminFilterForm = ({
                             filterConfig,
                             onNameChange,
                             onCountryChange,
                             onCityChange,
                             onCategoryChange,
                             onPostalCodeChange,
                             onActiveChange,
                             onDateChange,
                             onPriceChange,
                             maxPriceValue,
                             minPriceValue,
                         }) => {
    const {
        nameValue,
        countryValue,
        cityValue,
        categoryValue,
        postalCodeValue,
        activeValue,
        startDateValue,
        endDateValue,
        showNameFilter = true,
        showCountryFilter = false,
        showCityFilter = false,
        showCategoryFilter = false,
        showPostalCodeFilter = false,
        showActiveFilter = false,
        showDateFilter = false,
        showPriceFilter = false,
        namePlaceholder = "Nom",
        nameLabel = "Nom :",
    } = filterConfig;

    return (
        <form className="admin-filter-form">
            {showNameFilter && (
                <div className="name-select">
                    <label htmlFor="filterName">
                        <h4>{nameLabel}</h4>
                    </label>
                    <input
                        type="text"
                        name="filterName"
                        id="filterName"
                        value={nameValue}
                        onChange={(e) => onNameChange(e.target.value)}
                        placeholder={namePlaceholder}
                    />
                </div>
            )}

            <div className="other-filters">
                {showCountryFilter && (
                    <div className="country-select">
                        <label htmlFor="filterCountry">
                            <h4>Pays :</h4>
                        </label>
                        <GenericSelect
                            onChange={onCountryChange}
                            value={countryValue}
                            selector="cityReducer"
                            optionsTransformer={(data, activeOnly) =>
                                [...new Set(data
                                    .filter(city => activeOnly ? city.isActive : true)
                                    .map(city => city.country)
                                )].map(country => ({value: country, label: country}))
                            }
                            placeholder="Sélectionnez un pays"
                        />
                    </div>
                )}

                {showCityFilter && (
                    <div className="city-select">
                        <h4>Choisir la ville :</h4>
                        <GenericSelect
                            onChange={onCityChange}
                            value={cityValue}
                            selector="cityReducer"
                            optionsTransformer={(data) =>
                                data.map(city => ({value: city._id, label: city.name}))
                            }
                            placeholder="Ville"
                            isMulti
                        />
                    </div>
                )}

                {showCategoryFilter && (
                    <div className="category-select">
                        <h4>Choisir les catégories :</h4>
                        <GenericSelect
                            onChange={onCategoryChange}
                            value={categoryValue}
                            selector="categoryReducer"
                            optionsTransformer={(data) =>
                                data.map(category => ({value: category.name, label: category.name}))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            }
                            placeholder="Catégorie"
                            isMulti
                        />
                    </div>
                )}

                {showPostalCodeFilter && (
                    <div className="postalcode-select">
                        <label htmlFor="filterPostalCode">
                            <h4>Code Postal :</h4>
                        </label>
                        <input
                            type="text"
                            name="filterPostalCode"
                            id="filterPostalCode"
                            value={postalCodeValue}
                            onChange={(e) => onPostalCodeChange(e.target.value)}
                            placeholder="Code Postal"
                        />
                    </div>
                )}

                {showActiveFilter && (
                    <div className="active-select">
                        <h4>Activité</h4>
                        <select
                            onChange={(e) => onActiveChange(e.target.value)}
                            value={activeValue}
                            name="filterActive"
                            id="filterActive"
                        >
                            <option value="all">Actifs et inactifs</option>
                            <option value="active">Actifs seulement</option>
                            <option value="inactive">Inactifs seulement</option>
                        </select>
                    </div>
                )}

                {showDateFilter && (
                    <div className="date-filter">
                        <h4>Date de publication</h4>
                        <div className="date-publication">
                            <div className="date-input">
                                <label htmlFor="startDate">De :</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    value={startDateValue}
                                    onChange={(e) => onDateChange("start", e.target.value)}
                                />
                            </div>
                            <div className="date-input">
                                <label htmlFor="endDate">À :</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    value={endDateValue}
                                    onChange={(e) => onDateChange("end", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {showPriceFilter && (
                    <div className="price-filter">
                        <h4>Prix total</h4>
                        <div className="price-range">
                            <div className="price-input">
                                <label htmlFor="minPrice">Min :</label>
                                <input
                                    type="number"
                                    id="minPrice"
                                    value={minPriceValue}
                                    onChange={(e) => onPriceChange("min", e.target.value)}
                                />
                            </div>
                            <div className="price-input">
                                <label htmlFor="maxPrice">Max :</label>
                                <input
                                    type="number"
                                    id="maxPrice"
                                    value={maxPriceValue}
                                    onChange={(e) => onPriceChange("max", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default AdminFilterForm;