import React, {useEffect} from 'react';
import Header from "../layouts/Header";
import {useDispatch, useSelector} from "react-redux";
import Logo from "../components/LogoCity";
import {useParams} from "react-router";
import {setCurrentCity} from "../actions/city.action";
import {getContentCityById} from "../actions/contentCity.action";

const BasePages = ({children, navPath, stringBtn, state}) => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();
    const { citySubDomain } = useParams();


    useEffect(() => {
        const fetchCity = async () => {
            try {
                let correctedSubDomain = citySubDomain;
                if (correctedSubDomain === 'lagrandemotte') {
                    correctedSubDomain = 'la-grande-motte';
                }
                if (correctedSubDomain === 'legrauduroi') {
                    correctedSubDomain = 'le-grau-du-roi';
                }
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${correctedSubDomain}`);
                const data = await response.json();
                if(data.isActive) {
                    dispatch(setCurrentCity(data));
                    dispatch(getContentCityById(data.contentCity));
                }

            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        if (!currentCity.logo && citySubDomain) {
            fetchCity();
        }

    }, [currentCity.logo, citySubDomain, dispatch]);

    if(contentCurrentCity.length){
        dispatch(getContentCityById(currentCity.contentCity));
    }


    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="frontoffice-container" style={cityBackground}>
            <Header LogoComponent={Logo} navPath={navPath} stringBtn={stringBtn} state={state}/>
            {children}
        </div>
    );
};

export default BasePages;