import React, {useEffect, useState} from "react";
import AdminNewMessage from "../layouts/AdminNewMessage";
import {isEmpty} from "../assets/Utils";
import {useDispatch, useSelector} from "react-redux";
import {getMessage} from "../actions/message.action";
import AdminUpdateMessage from "../layouts/AdminUpdateMessage";
import BasePagesBackoffice from "./BasePagesBackoffice";
import AdminFilterForm from "../components/AdminFilterForm";

const AdminMessages = () => {
    const [nameMessageSorting, setNameMessageSorting] = useState("");
    const dataMessage = useSelector((state) => state.messageReducer);
    const [sortingCityValue, setSortingCityValue] = useState([]);
    const [sortingCityName, setSortingCityName] = useState("");
    const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
    const [filteredMessage, setFilteredMessage] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const dispatch = useDispatch();
    const [selectedElement, setselectedElement] = useState(null);
    const [popupUpdateMessage, setPopupUpdateMessage] = useState(false);

    const normalizeString = (string) => {
        return string
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    };

    const handleCitySelected = (value) => {
        setSortingCityValue(value);
    };

    useEffect(() => {
        if (!isEmpty(sortingCityValue)) {
            let tempValues = [];
            sortingCityValue.forEach((city) => {
                tempValues.push(city.label);
            });
            setSortingCityName(tempValues);
        } else {
            setSortingCityName("");
        }
    }, [sortingCityValue]);


    useEffect(() => {
        const tempFilteredData = dataMessage
            .filter((message) =>
                normalizeString(message.title).includes(
                    normalizeString(nameMessageSorting)
                )
            )
            .filter(
                (message) =>
                    isEmpty(sortingCityValue) ||
                    message.city.some((city) => {
                        return sortingCityName.includes(city);
                    })
            )

            .filter((message) => {
                const publicationDate = new Date(message.datePublication);
                const start = startDate ? new Date(startDate) : null;
                const end = endDate ? new Date(endDate) : null;
                return (
                    (!start || publicationDate >= start) &&
                    (!end || publicationDate <= end)
                );
            })
            .sort((a, b) => a.title.localeCompare(b.title));
        setFilteredMessage(tempFilteredData);
        setIsFilteredEmpty(isEmpty(tempFilteredData));
    }, [
        dataMessage,
        nameMessageSorting,
        sortingCityName,
        startDate,
        endDate
    ]);

    const handleMessageClick = (message) => {
        setselectedElement(message);
        setPopupUpdateMessage(true);
    };

    const handleRefreshPartners = () => {
        dispatch(getMessage());
        window.alert(
            "Tableau des messages actualisé.\nTous les messages sont à présent modifiables et activables."
        );
    };

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-messages">
                <AdminNewMessage/>
                <section className="message-management">
                    <h2>Gestion des messages</h2>
                    <AdminFilterForm
                        filterConfig={{
                            nameValue: nameMessageSorting,
                            cityValue: sortingCityValue,
                            startDateValue: startDate,
                            endDateValue: endDate,
                            showCityFilter: true,
                            showDateFilter: true,
                            namePlaceholder: "Titre du message",
                            nameLabel: "Titre du message :",
                        }}
                        onNameChange={setNameMessageSorting}
                        onCityChange={handleCitySelected}
                        onDateChange={(type, value) => {
                            if (type === "start") setStartDate(value);
                            else setEndDate(value);
                        }}
                    />
                    <button className="btn-refresh" onClick={handleRefreshPartners}>
                        Actualiser <i className="bx bx-refresh"></i>
                    </button>
                    {isFilteredEmpty ? (
                        <p>
                            Aucun message ne correspond à votre recherche. Veuillez
                            réessayer.
                        </p>
                    ) : (
                        <section className="message-overview">
                            {filteredMessage.map((message) => (
                                <div
                                    key={message._id}
                                    className="message"
                                    onClick={() => handleMessageClick(message)}
                                >
                                    <img
                                        src={
                                            "https://api.lesbonsplans.pro/upload/partner/image/" +
                                            message.logo
                                        }
                                        alt="image visite"
                                    />
                                    <p>{message.title}</p>
                                </div>
                            ))}
                        </section>
                    )}
                </section>
                {popupUpdateMessage && selectedElement && (
                    <AdminUpdateMessage
                        message={selectedElement}
                        closePopup={() => setPopupUpdateMessage(false)}
                    />
                )}
            </section>
        </BasePagesBackoffice>
    );
}
export default AdminMessages;