import React, {useState} from 'react';
import AdminNewAlbum from "../layouts/AdminNewAlbum";
import AdminTable from "../components/AdminTable";
import AdminUpdateAlbum from "../layouts/AdminUpdateAlbum";
import {useSelector, useDispatch} from "react-redux";
import {deleteAlbum, getAlbum} from "../actions/album.action";
import BasePagesBackoffice from "./BasePagesBackoffice";

const AdminAlbum = () => {
    const dataAlbum = useSelector((state) => state.albumReducer);
    const dataContentCity = useSelector((state) => state.contentCityReducer);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const dispatch = useDispatch();

    const handleEditAlbum = (album) => {
        setSelectedAlbum(album);
    };

    const closeUpdateAlbumModal = () => {
        setSelectedAlbum(null);
    };

    const getContentCityIdByAlbumId = (albumId) => {
        return dataContentCity
            .filter((contentCity) => contentCity.albums.includes(albumId))
            .map((contentCity) => contentCity._id);
    }

    const handleDeleteClick = (album) => {
        const contentCityIds = getContentCityIdByAlbumId(album._id);
        dispatch(deleteAlbum(album, contentCityIds));
    };

    const columns = [
        {header: 'Titre', render: (album) => album.name},
        {
            header: 'Description',
            render: (album) => album.description.length > 100 ? album.description.substring(0, 100) + '...' : album.description
        },
        {
            header: 'Logo Groupe',
            render: (album) => <img className="city-background-image"
                                    src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoGroupe}`}
                                    alt={"Logo " + album.logoGroupe}/>
        },
        {
            header: 'Logo Album',
            render: (album) => <img className="city-background-image"
                                    src={`https://api.lesbonsplans.pro/upload/partner/image/${album.logoAlbum}`}
                                    alt={"Logo " + album.logoAlbum}/>
        },
        {header: 'Coût Particulier', render: (album) => album.cost.particulier},
        {header: 'Coût Pro', render: (album) => album.cost.pro},
        {
            header: "Modifications",
            render: (album) => <button onClick={() => handleEditAlbum(album)}>Modifier</button>
        },
        {
            header: "Suppression",
            render: (album) => <button onClick={() => handleDeleteClick(album)}>Supprimer</button>
        }
    ];

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"} >
            <section className="admin-album">
                <section className="new-album">
                    <AdminNewAlbum/>
                </section>
                <section className="albums-management">
                    <h2>Gestion des albums</h2>
                    <AdminTable
                        columns={columns}
                        data={dataAlbum}
                        onEdit={handleEditAlbum}
                        onDelete={handleDeleteClick}
                        itemName="cet album"
                        onRefresh={getAlbum()}
                    />
                </section>
                {selectedAlbum && <AdminUpdateAlbum album={selectedAlbum} closePopup={closeUpdateAlbumModal} />}
            </section>
        </BasePagesBackoffice>
    );
};

export default AdminAlbum;