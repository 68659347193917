import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const GenericSelect = ({
                           onChange,
                           value,
                           selector,
                           optionsTransformer,
                           placeholder,
                           isMulti = false,
                           isClearable = true,
                           isSearchable = true,
                           activeOnly = false,
                           enableSelectAll = false,
                       }) => {
    const data = useSelector((state) => state[selector]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (Array.isArray(data)) {
            let transformedOptions = optionsTransformer(data, activeOnly);
            if (enableSelectAll && isMulti) {
                transformedOptions = [
                    {label: "Tout sélectionner", value: "select_all"},
                    ...transformedOptions,
                ];
            }
            setOptions(transformedOptions);
        }
    }, [data, activeOnly, optionsTransformer, enableSelectAll, isMulti]);

    const handleChange = (selectedOptions) => {
        if (isMulti && enableSelectAll) {
            const isSelectAllSelected = selectedOptions.some(
                (option) => option.value === "select_all"
            );
            if (isSelectAllSelected) {
                if (selectedOptions.length === options.length) {
                    onChange([]); // Deselect all
                } else {
                    onChange(options.filter((option) => option.value !== "select_all")); // Select all
                }
            } else {
                onChange(selectedOptions);
            }
        } else {
            onChange(selectedOptions);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            flex: "1",
            minWidth: "200px",
            height: "50px",
            margin: "20px 0",
            padding: "5px",
            backgroundColor: "#e2b259",
            border: "2px solid #fff",
            boxShadow: "none",
            transition: "0.2s ease",
            "&:hover": {
                border: "2px solid #ccc",
                cursor: "text",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
            color: state.isFocused ? "white" : "black",
            ":active": {
                backgroundColor: "white",
                color: "black",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#b1852d",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "white",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: "white",
            "&:hover": {
                backgroundColor: "red",
                color: "white",
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            transition: "0.2s ease",
            "&:hover": {
                color: "#ccc",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            border: "2px solid white",
            borderRadius: "4px",
            marginTop: "4px",
            zIndex: 9999,
        }),
    };

    return (
        <Select
            options={options}
            onChange={handleChange}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
        />
    );
};

export default GenericSelect;