import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import Magazines from "./pages/Magazines";
import SocialMedia from "./pages/SocialMedia";
import PartnersList from "./pages/PartnersList";
import Partner from "./pages/Partner";
import HomeRedirect from "./pages/HomeRedirect";
import CitiesList from "./pages/CitiesList";
import Connexion from "./pages/Connexion";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import HomeAdmin from "./pages/HomeAdmin";
import AdminUsers from "./pages/AdminUsers";
import AdminCities from "./pages/AdminCities";
import AdminPartners from "./pages/AdminPartners";
import AdminMessages from "./pages/AdminMessages";
import CategoriesList from "./pages/CategoriesList";
import StreetsIndex from "./pages/StreetsIndex";
import AdminContent from "./pages/AdminContent";
import IframeLink from "./pages/IframeLink";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import WidgetPagesJaunes from "./pages/WidgetPagesJaunes";
import GuidedTourList from "./pages/GuidedTourList";
import GuidedTour from "./pages/GuidedTour";
import MagazineRedirect from "./pages/MagazineRedirect";
import MessagesList from "./pages/MessagesList";
import Message from "./pages/Message";
import InterviewList from "./pages/InterviewList";
import Interview from "./pages/Interview";
import Statistiques from "./pages/Statistiques";
import CityContent from "./pages/CityContent";
import AdminNews from "./pages/AdminNews";
import NewAccountUser from "./pages/NewAccountUser";
import AccountUser from "./pages/AccountUser";
import AdminAlbum from "./pages/AdminAlbum";
import AlbumList from "./pages/AlbumList";
import Album from "./pages/Album";
import Cart from "./pages/Cart";
import {useDispatch} from "react-redux";
import axios from "axios";
import {login} from "./actions/auth.action";
import {loadCartFromLocalStorage} from "./actions/cart.action";
import FlashMessageContainer from "./components/FlashMessageContainer";
import AdminInvoice from "./pages/AdminInvoice";

const ProtectedRoute = ({children, allowedRoles}) => {
    const userConnect = useSelector((state) => state.authReducer);

    if (!userConnect.isAuthenticated || !allowedRoles.includes(userConnect.user.userLevels[0])) {
        window.location.href = "/connexion";
        return null;
    }

    return children;
};

const ForbiddenRouteConnected = ({children}) => {
    const userConnect = useSelector((state) => state.authReducer);

    if (userConnect.isAuthenticated) {
        window.location.href = "/administration/accueil";
        return null;
    }

    return children;
}

const CityRoute = ({children}) => {
    const currentCity = useSelector((state) => state.cityReducer);
    if (Array.isArray(currentCity)) {
        window.location.href = "/ma-ville";
        return null;
    }
    return children;
};

const LocationTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'G-TYEHMBQN67', {
                page_path: location.pathname + location.search,
            });
        }
    }, [location]);

    return null;
};

const App = () => {
    const userConnect = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !userConnect.isAuthenticated) {
            axios.get(`${API_URL}/user/verify-token`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.data.valid) {
                        dispatch(login(res.data.user));
                    } else {
                        localStorage.removeItem('token');
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('token');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, userConnect.isAuthenticated]);

    useEffect(() => {
        dispatch(loadCartFromLocalStorage());
    }, [dispatch]);


    const routes = useMemo(() => [
        // Pages de redirections
        {path: "/", element: <HomeRedirect/>},
        {path: "*", element: <HomeRedirect/>},
        {path: "/ma-ville", element: <HomeRedirect/>},
        {path: "/selection-ville", element: <CitiesList/>},
        {path: "/pages-jaunes", element: <WidgetPagesJaunes/>},

        // Pages utilisateur non connecté
        {path: "/connexion", element: <ForbiddenRouteConnected><Connexion/></ForbiddenRouteConnected>},
        {path: "/mot-de-passe-oublie", element: <ForbiddenRouteConnected><ForgetPassword/></ForbiddenRouteConnected>},
        {path: "/reinitialisation-mot-de-passe/:token", element: <ForbiddenRouteConnected><ResetPassword/></ForbiddenRouteConnected>},
        {path: "/creation-du-compte", element: <ForbiddenRouteConnected><NewAccountUser/></ForbiddenRouteConnected>},

        // Pages d'administration
        {path: "/administration/accueil", element: <ProtectedRoute allowedRoles={[1, 2, 3, 4, 5]}><HomeAdmin/></ProtectedRoute>},
        {path: "/administration/utilisateurs", element: <ProtectedRoute allowedRoles={[1]}><AdminUsers/></ProtectedRoute>},
        {path: "/administration/gestion-villes", element: <ProtectedRoute allowedRoles={[1]}><AdminCities/></ProtectedRoute>},
        {path: "/administration/contenu-ville", element: <ProtectedRoute allowedRoles={[1]}><AdminContent/></ProtectedRoute>},
        {path: "/administration/partenaires", element: <ProtectedRoute allowedRoles={[1]}><AdminPartners/></ProtectedRoute>},
        {path: "/administration/notifications", element: <ProtectedRoute allowedRoles={[1]}><AdminMessages/></ProtectedRoute>},
        {path: "/administration/statistiques",element: <ProtectedRoute allowedRoles={[1]}><Statistiques/></ProtectedRoute>},
        {path: "/administration/actualites", element: <ProtectedRoute allowedRoles={[1, 2, 3]}><AdminNews/></ProtectedRoute>},
        {path: "/administration/albums", element: <ProtectedRoute allowedRoles={[1]}><AdminAlbum/></ProtectedRoute>},
        {path: "/administration/factures", element: <ProtectedRoute allowedRoles={[1]}><AdminInvoice/></ProtectedRoute>},



        // Pages utilisateur backend
        {path: "/compte-utilisateur", element: <ProtectedRoute allowedRoles={[4, 5]}><AccountUser/></ProtectedRoute>},



        // Pages utilisateur frontend
        {path: "/accueil", element: <CityRoute><Home/></CityRoute>},
        {path: "/magazines", element: <CityRoute><MagazineRedirect/></CityRoute>},
        {path: "/nos-magazines/:citySubDomain", element: <Magazines/>},
        {path: "/les-actualites", element: <CityRoute><CityContent title="Actualités de la ville" contentType="actuality"/></CityRoute>},
        {path: "/suivez-nous", element: <CityRoute><CityContent title="Suivez-nous !" contentType="social-media"/></CityRoute>},
        {path: "/musiques", element: <CityRoute><CityContent title="Musiques !" contentType="music"/></CityRoute>},
        {path: "/agenda", element: <CityRoute><IframeLink/></CityRoute>},
        {path: "/reseaux-sociaux", element: <CityRoute><SocialMedia/></CityRoute>},
        {path: "/nos-messages", element: <CityRoute><MessagesList/></CityRoute>},
        {path: "/message", element: <CityRoute><Message/></CityRoute>},
        {path: "/interviewList", element: <CityRoute><InterviewList/></CityRoute>},
        {path: "/interview", element: <CityRoute><Interview/></CityRoute>},
        {path: "/categories", element: <CityRoute><CategoriesList/></CityRoute>},
        {path: "/nos-partenaires/:citySubDomain/:categorysubDomain", element: <PartnersList/>},
        {path: "/partenaires/:citySubDomain/:categorysubDomain/:partnersubDomain", element: <Partner/>},
        {path: "/decouverte", element: <CityRoute><CityContent title="Découverte" contentType="guide"/></CityRoute>},
        {path: "/index-rues", element: <CityRoute><StreetsIndex/></CityRoute>},
        {path: "/iframe-link", element: <CityRoute><IframeLink/></CityRoute>},
        {path: "/magazine-de-la-ville", element: <CityRoute><IframeLink/></CityRoute>},
        {path: "/visites-guidees", element: <CityRoute><GuidedTourList/></CityRoute>},
        {path: "/visite-guidee", element: <CityRoute><GuidedTour/></CityRoute>},
        {path: "/contact", element: <CityRoute><Contact/></CityRoute>},
        {path: "/GuidePrestige", element: <CityRoute><IframeLink/></CityRoute>},
        {path: "/albums", element: <CityRoute><AlbumList/></CityRoute>},
        {path: "/album", element: <CityRoute><Album/></CityRoute>},
        {path: "/panier", element: <CityRoute><Cart/></CityRoute>},
        {path: "/politique-confidentialité", element: <CityRoute><PrivacyPolicy/></CityRoute>},



        // Pages utilisateur frontend URL
        {path: "/accueil/:citySubDomain", element: <Home/>},
        {path: "/musiques/:citySubDomain", element: <CityContent title="Musiques !" contentType="music"/>},


    ], []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            <LocationTracker/>
            <FlashMessageContainer/>
            <Routes>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}/>
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default App;