import React, {useState, useEffect} from 'react';
import BasePages from "./BasePages";
import PaginatedList from "../components/PaginatedList";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import StatusUser from "../components/StatusUser";
import {removeFromCart} from "../actions/cart.action";
import {showFlashMessage} from "../actions/flashMessage.action";
import {useLocation} from "react-router-dom";

const AlbumList = () => {
    const location = useLocation();
    const action = location.state?.action;
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dataAlbum = useSelector((state) => state.albumReducer);
    const navigate = useNavigate();
    const [selectedTypeUser, setSelectedTypeUser] = useState(localStorage.getItem('selectedTypeUser') || null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userConnect = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const albums = dataAlbum.filter(album => contentCurrentCity.albums && contentCurrentCity.albums.includes(album._id));

    const handleAlbumClick = (album) => {
        if (!selectedTypeUser && !userConnect.user && action === 'buy') {
            return dispatch(showFlashMessage("Choisissez un status pour accéder aux albums."));
        }
        navigate('/album', { state: { album, action } });
    };

    const handleTypeUserChange = (e) => {
        const {id, checked} = e.target;
        if (checked) {
            setSelectedTypeUser(id);
            localStorage.setItem('selectedTypeUser', id);
        } else {
            setSelectedTypeUser(null);
            localStorage.removeItem('selectedTypeUser');
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const navigateToCart = async () => {
        if (!selectedTypeUser && !userConnect.user) {
            return dispatch(showFlashMessage("Choisissez un status pour accéder à votre panier."))
        }
        const cart = JSON.parse(localStorage.getItem("cart")) || {items: []};
        const items = cart.items || [];
        const albums = items.filter(item => item.name);

        albums.forEach(album => {
            album.content.forEach(audio => {
                const index = items.findIndex(item => item._id === audio._id);
                if (index !== -1) {
                    items.splice(index, 1);
                    dispatch(removeFromCart(audio));
                }
            });
        });

        await localStorage.setItem("cart", JSON.stringify({items}));
        navigate('/panier');
    }

    useEffect(() => {
        if (selectedTypeUser && action ===  'buy') {
            document.getElementById(selectedTypeUser).checked = true;
        }
    }, [selectedTypeUser]);

    return (
        <BasePages navPath="musiques" stringBtn="Retourner aux musiques">
            <h1>Nos Albums</h1>
            <section className="album-list">
                {action === 'buy' && !userConnect.user && (
                    <StatusUser
                        selectedTypeUser={selectedTypeUser}
                        handleTypeUserChange={handleTypeUserChange}
                        openModal={openModal}
                        closeModal={closeModal}
                        isModalOpen={isModalOpen}
                    />
                )}
                <PaginatedList
                    items={albums}
                    renderItem={(album) => (
                        <div
                            className="album-list-select"
                            key={album._id}
                            style={{
                                backgroundImage: `url(${"https://api.lesbonsplans.pro"}/upload/partner/image/${album.logoAlbum})`,
                                backgroundSize: 'cover'
                            }}
                            onClick={() => handleAlbumClick(album)}
                        >
                            <h2>{album.name}</h2>
                        </div>
                    )}
                />
                {action === 'buy' && (
                    <div className="album-download">
                        <button className="button-download" onClick={navigateToCart}>Voir la panier</button>
                    </div>
                )}
            </section>
        </BasePages>
    );
};

export default AlbumList;