import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deletePartner, getPartner, updatePartner} from "../actions/partner.action";
import AdminNewPartner from "../layouts/AdminNewPartner";
import PopupPartnerInfos from "../layouts/PopupPartnerInfos";
import PopupPartnerContent from "../layouts/PopupPartnerContent";
import AdminUpdatePartner from "../layouts/AdminUpdatePartner";
import AdminCategoryManagement from "../layouts/AdminCategoryManagement";
import AdminTable from "../components/AdminTable";
import {isEmpty} from "../assets/Utils";
import BasePagesBackoffice from "./BasePagesBackoffice";
import AdminFilterForm from "../components/AdminFilterForm";

const AdminPartners = () => {
    const dataPartner = useSelector((state) => state.partnerReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const dataAuth = useSelector((state) => state.authReducer);
    const [isAdminPartner, setIsAdminPartner] = useState(false);
    const [namePartnerSorting, setNamePartnerSorting] = useState("");
    const [sortingCityValue, setSortingCityValue] = useState([]);
    const [sortingCityId, setSortingCityId] = useState("");
    const [sortingCategoryValue, setSortingCategoryValue] = useState([]);
    const [sortingCategoryName, setSortingCategoryName] = useState("");
    const [sortingActive, setSortingActive] = useState("all");
    const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [slicedFilteredPartners, setSlicedFilteredPartners] = useState([]);
    const [popupPartnerInfos, setPopupPartnerInfos] = useState({});
    const [popupPartnerContent, setPopupPartnerContent] = useState({});
    const [popupPartnerUpdate, setPopupPartnerUpdate] = useState({});
    const [currentPageTablePartner, setCurrentPageTablePartner] = useState(1);
    const itemsPerPage = 20;
    const indexOfLastItem = currentPageTablePartner * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const dispatch = useDispatch();

    const handleCitySelected = (value) => {
        setSortingCityValue(value);
    };

    const handleCategorySelected = (value) => {
        setSortingCategoryValue(value);
    };

    const normalizeString = (string) => {
        return string
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
    };

    useEffect(() => {
        if (!isEmpty(dataAuth)) {
            if (
                dataAuth.user.userLevels.includes(1) ||
                dataAuth.user.userLevels.includes(5)
            ) {
                setIsAdminPartner(true);
            }
        }
    }, [dataAuth]);

    useEffect(() => {
        if (!isEmpty(sortingCityValue)) {
            let tempValues = [];
            sortingCityValue.forEach((city) => {
                tempValues.push(city.value);
            });
            setSortingCityId(tempValues);
        } else {
            setSortingCityId("");
        }
    }, [sortingCityValue]);

    useEffect(() => {
        if (!isEmpty(sortingCategoryValue)) {
            let tempValues = [];
            sortingCategoryValue.forEach((category) => {
                tempValues.push(category.value);
            });
            setSortingCategoryName(tempValues);
        } else {
            setSortingCategoryName("");
        }
    }, [sortingCategoryValue]);

    useEffect(() => {
        const tempFilteredData = dataPartner
            .filter((partner) =>
                normalizeString(partner.name).includes(
                    normalizeString(namePartnerSorting)
                )
            )
            .filter(
                (partner) =>
                    isEmpty(sortingCityValue) || sortingCityId.includes(partner.city)
            )
            .filter(
                (partner) =>
                    isEmpty(sortingCategoryValue) ||
                    partner.category.some((category) => {
                        return sortingCategoryName.includes(category);
                    })
            )
            .filter((partner) => {
                if (sortingActive === "all") {
                    return true;
                } else if (sortingActive === "active") {
                    return partner.isActive;
                } else {
                    return !partner.isActive;
                }
            })
            .sort((a, b) => a.name.localeCompare(b.name));
        if (isEmpty(tempFilteredData)) {
            setIsFilteredEmpty(true);
        } else {
            setIsFilteredEmpty(false);
        }
        setFilteredPartners(tempFilteredData);
        setCurrentPageTablePartner(1);
    }, [
        dataPartner,
        namePartnerSorting,
        sortingCityId,
        sortingCategoryName,
        sortingActive,
    ]);

    useEffect(() => {
        let tempSlicedPartner = filteredPartners.slice(
            indexOfFirstItem,
            indexOfLastItem
        );
        setSlicedFilteredPartners(tempSlicedPartner);
    }, [currentPageTablePartner, filteredPartners]);

    const handleActivatePartner = (e, partner) => {
        const updatedPartner = {...partner, isActive: !partner.isActive};
        dispatch(updatePartner(updatedPartner));
    };

    const handleNextPageTablePartner = () => {
        setCurrentPageTablePartner(currentPageTablePartner + 1);
    };

    const handlePreviousPageTablePartner = () => {
        setCurrentPageTablePartner(currentPageTablePartner - 1);
    };

    const handleDeleteClick = (partner) => {
        dispatch(deletePartner(partner));
    };

    const columns = [
        {header: "Nom du partenaire", render: (partner) => partner.name},
        {
            header: "Ville",
            render: (partner) =>
                !isEmpty(dataCity) &&
                dataCity.find((city) => city._id === partner.city).acronym +
                " (" +
                dataCity.find((city) => city._id === partner.city).postalCode +
                ")"
        },
        {
            header: "Adresse",
            render: (partner) => `${partner.streetNumber} ${partner.streetName ? partner.streetName : ""}`
        },
        {
            header: "Catégories",
            render: (partner) =>
                partner.category.map((cat, index) => (
                    <span key={index} className={index === 0 ? "first-category" : ""}>
                        {cat}
                        {index < partner.category.length - 1 && ", "}
                    </span>
                ))
        },
        {
            header: "Contacts",
            render: (partner) =>
                partner._id ? (
                    <button onClick={() => setPopupPartnerInfos(partner)}>Voir les contacts</button>
                ) : (
                    <button disabled>Actualisez pour visualiser</button>
                )
        },
        {
            header: "Contenu",
            render: (partner) =>
                partner._id ? (
                    <button onClick={() => setPopupPartnerContent(partner)}>Voir le contenu</button>
                ) : (
                    <button disabled>Actualisez pour visualiser</button>
                )
        },
        {
            header: "Actif",
            render: (partner) => (partner.isActive ? "Oui" : "Non")
        },
        {
            header: "Activation",
            render: (partner) =>
                partner._id ? (
                    <button onClick={(e) => handleActivatePartner(e, partner)}>
                        {partner.isActive ? "Désactiver" : "Activer"}
                    </button>
                ) : (
                    <button disabled>Actualisez pour Activer/Désactiver</button>
                )
        },
        {
            header: "Modifications",
            render: (partner) =>
                partner._id ? (
                    <button onClick={() => setPopupPartnerUpdate(partner)}>Modifier</button>
                ) : (
                    <button disabled>Actualisez pour Modifier</button>
                )
        },
        {
            header: "Suppression",
            render: (partner) =>
                partner._id ? (
                    <button onClick={() => handleDeleteClick(partner)}>Supprimer</button>
                ) : (
                    <button disabled>Actualisez pour Supprimer</button>
                )
        }
    ];

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="admin-partner">
                {isAdminPartner && <AdminNewPartner popupActive={popupPartnerUpdate}/>}
                <AdminCategoryManagement/>
                <section className="partner-management">
                    <h2>Gestion des partenaires</h2>
                    <AdminFilterForm
                        filterConfig={{
                            nameValue: namePartnerSorting,
                            cityValue: sortingCityValue,
                            categoryValue: sortingCategoryValue,
                            activeValue: sortingActive,
                            showCityFilter: true,
                            showCategoryFilter: true,
                            showActiveFilter: true,
                            namePlaceholder: "Nom de partenaire",
                            nameLabel: "Nom de partenaire :",
                        }}
                        onNameChange={setNamePartnerSorting}
                        onCityChange={handleCitySelected}
                        onCategoryChange={handleCategorySelected}
                        onActiveChange={setSortingActive}
                    />
                    {isFilteredEmpty ? (
                        <p>
                            Aucun partenaire ne correspond à votre recherche. Veuillez
                            réessayer.
                        </p>
                    ) : (
                        <>
                            <AdminTable
                                columns={columns}
                                data={slicedFilteredPartners}
                                onDelete={handleDeleteClick}
                                itemName="ce partenaire"
                                onRefresh={getPartner()}
                            />
                            <div>
                                <button
                                    onClick={handlePreviousPageTablePartner}
                                    hidden={currentPageTablePartner === 1}
                                >
                                    Précédent
                                </button>
                                <button
                                    onClick={handleNextPageTablePartner}
                                    hidden={indexOfLastItem >= filteredPartners.length}
                                >
                                    Suivant
                                </button>
                            </div>
                        </>
                    )}
                </section>
                {!isEmpty(popupPartnerInfos) && (
                    <PopupPartnerInfos
                        partner={popupPartnerInfos}
                        closePopup={setPopupPartnerInfos}
                    />
                )}
                {!isEmpty(popupPartnerContent) && (
                    <PopupPartnerContent
                        partner={popupPartnerContent}
                        closePopup={setPopupPartnerContent}
                    />
                )}
                {!isEmpty(popupPartnerUpdate) && (
                    <AdminUpdatePartner
                        partner={popupPartnerUpdate}
                        closePopup={setPopupPartnerUpdate}
                    />
                )}
            </section>
        </BasePagesBackoffice>

    );
};

export default AdminPartners;