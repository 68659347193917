import axios from "axios";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TRY_CONNECTION = "TRY_CONNECTION";

export const login = (user) => ({
  type: LOGIN,
  payload: user,
});

// Dans auth.action.js
export const logout = () => {
  localStorage.removeItem('token');
  return { type: LOGOUT };
};

const API_URL = process.env.REACT_APP_API_URL;


// Dans auth.action.js
export const handleUserConnection = (email, password) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`${API_URL}/user/login`, {
                email: email,
                password: password,
            });

            if (res.data.response) {
                localStorage.setItem('token', res.data.token);
                dispatch({ type: TRY_CONNECTION, payload: res.data });
            } else {
                dispatch({
                    type: TRY_CONNECTION,
                    payload: {
                        response: false,
                        message: res.data.message,
                        field: res.data.field
                    }
                });
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    };
};
