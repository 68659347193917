import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {format} from 'date-fns';
import AdminTable from "../components/AdminTable";
import {getUser, updateUser} from "../actions/user.action";
import {showFlashMessage} from '../actions/flashMessage.action';
import BasePagesBackoffice from "./BasePagesBackoffice";
import {downloadAlbum} from "../actions/album.action";

const AccountUser = () => {
    const dispatch = useDispatch();
    const dataUser = useSelector((state) => state.authReducer.user);
    const [isEditing, setIsEditing] = useState(false);
    const userConnect = useSelector((state) => state.authReducer);
    const [userInfo, setUserInfo] = useState({
        lastName: dataUser.lastName || '',
        firstName: dataUser.firstName || '',
        phoneNumber: dataUser.phoneNumber || '',
        email: dataUser.email || '',
        codePostal: dataUser.codePostal || '',
        country: dataUser.country || ''
    });
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        setUserInfo({
            lastName: dataUser.lastName || '',
            firstName: dataUser.firstName || '',
            phoneNumber: dataUser.phoneNumber || '',
            email: dataUser.email || '',
            codePostal: dataUser.codePostal || '',
            country: dataUser.country || ''
        });
    }, [dataUser]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserInfo({...userInfo, [name]: value});
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setUserInfo({
            lastName: dataUser.lastName || '',
            firstName: dataUser.firstName || '',
            phoneNumber: dataUser.phoneNumber || '',
            email: dataUser.email || '',
            codePostal: dataUser.codePostal || '',
            country: dataUser.country || ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        userInfo._id = dataUser._id;

        try {
            const updatedUser = await dispatch(updateUser(userInfo));
            if (updatedUser) {
                setUserInfo({
                    lastName: updatedUser.lastName || '',
                    firstName: updatedUser.firstName || '',
                    phoneNumber: updatedUser.phoneNumber || '',
                    email: updatedUser.email || '',
                    codePostal: updatedUser.codePostal || '',
                    country: updatedUser.country || ''
                });

                if (userInfo.email !== dataUser.email) {
                    dispatch(showFlashMessage("Un email a été envoyé pour modifier et valider votre nouvelle adresse e-mail.", "green"));
                } else {
                    dispatch(showFlashMessage("Vos informations ont été mises à jour.", "green"));
                }
            }

            setIsEditing(false);
        } catch (error) {
            if (error.response && error.response.data.message === "Cet email est déjà utilisé.") {
                dispatch(showFlashMessage("Cet email est déjà utilisé. Veuillez en choisir un autre.", "red"));
            } else {
                dispatch(showFlashMessage("Une erreur est survenue lors de la mise à jour.", "red"));
            }
        }
    };

    const handleImageClick = (src) => {
        setSelectedImage(src);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const handleDownload = (url, filename = 'facture.png') => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
            })
            .catch(error => console.error('Erreur lors du téléchargement:', error));
    };

    const handleDownloadAlbum = () => {
        try {
            dispatch(downloadAlbum(userConnect.user._id));
            dispatch(showFlashMessage('Téléchargement de l\'album réussi', 'green', 3000));
        } catch (error) {
            dispatch(showFlashMessage('Erreur lors du téléchargement de l\'album', 'red', 3000));
        }
    };

    const columns = [
        {header: 'Date de facture', render: (item) => format(new Date(item.date), 'dd/MM/yyyy / HH:mm:ss')},
        {header: 'Numéro de Facture', render: (item) => item._id},
        {
            header: 'Voir la Facture', render: (item) => <img
                src={`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`}
                alt={"Logo " + item._id}
                onClick={() => handleImageClick(`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`)}
            />
        },
        {
            header: 'Facture',
            render: () => <button
                onClick={() => handleDownload(`https://api.lesbonsplans.pro/upload/partner/image/67c180750588803852049e35`)}>
                Télécharger
            </button>
        },
        {
            header: "Musiques",
            render: () => (
                <button
                    onClick={() =>
                        handleDownloadAlbum()
                    }
                >
                    Télécharger
                </button>
            ),
        },
    ];

    function handleDelete() {
        // Logic to delete the selected item
    }

    const sortedData = [...dataUser.listPurchase].sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <BasePagesBackoffice navPath={"accueil"} stringBtn={"Retourner à la page d'accueil"}>
            <section className="account-user">
                <section>
                    <h2>Vos informations personnelles</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="info-account-user">
                            <div className="info-personnels-account-user">
                                <div className="info-field">
                                    <label>Nom :</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={userInfo.lastName}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.lastName || 'Non renseigné'}</p>
                                    )}
                                </div>
                                <div className="info-field">
                                    <label>Prénom :</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={userInfo.firstName}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.firstName || 'Non renseigné'}</p>
                                    )}
                                </div>
                                <div className="info-field">
                                    <label>Numéro de téléphone :</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            value={userInfo.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.phoneNumber || 'Non renseigné'}</p>
                                    )}
                                </div>
                            </div>
                            <div className="info-personnels-account-user">
                                <div className="info-field">
                                    <label>Adresse e-mail :</label>
                                    {isEditing ? (
                                        <input
                                            type="email"
                                            name="email"
                                            value={userInfo.email}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.email || 'Non renseigné'}</p>
                                    )}
                                </div>
                                <div className="info-field">
                                    <label>Code Postal :</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="codePostal"
                                            value={userInfo.codePostal}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.codePostal || 'Non renseigné'}</p>
                                    )}
                                </div>
                                <div className="info-field">
                                    <label>Pays :</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            name="country"
                                            value={userInfo.country}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <p>{userInfo.country || 'Non renseigné'}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="button-account-user">
                            {isEditing ? (
                                <div className="button-group">
                                    <button type="button" onClick={handleCancel}>Annuler</button>
                                    <button type="submit">Valider</button>
                                </div>
                            ) : (
                                <button type="button" onClick={handleEdit}>Modifier mes informations</button>
                            )}
                        </div>
                    </form>
                </section>
                <section className="facture-user">
                    <div>
                        <h2>Historique des factures</h2>
                        <AdminTable columns={columns} data={sortedData} onDelete={handleDelete}
                                    itemName="facture"
                                    onRefresh={getUser()}/>
                    </div>
                </section>
                {selectedImage && (
                    <div className="modal-bigger-picture" onClick={handleCloseModal}>
                        <div className="modal-content">
                            <img src={selectedImage} alt="Facture"/>
                        </div>
                    </div>
                )}
            </section>
        </BasePagesBackoffice>
    );
};

export default AccountUser;