import axios from "axios";
import {deleteElement} from "./partnerUploads.action";

export const GET_USER = "GET_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
const API_URL = process.env.REACT_APP_API_URL;

export const getUser = () => {
    return async (dispatch) => {
        return axios
            .get(`${API_URL}/user`)
            .then((res) => {
                dispatch({type: GET_USER, payload: res.data});
            })
            .catch((err) => console.log(err));
    };
};

export const getUserById = (userId) => {
    return async (dispatch) => {
        try {
            const res = await axios.get(`${API_URL}/user/${userId}`);
            dispatch({type: GET_USER_BY_ID, payload: res.data});
        } catch (err) {
            console.log(err);
        }
    };
};

export const createUser = (userData) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/user`, userData);
            return {
                payload: {
                    success: true,
                    user: response.data
                }
            };
        } catch (error) {
            if (error.response && error.response.status === 400 &&
                error.response.data.message.includes("email")) {
                return {
                    payload: {
                        success: false,
                        error: "EMAIL_EXISTS",
                        message: error.response.data.message
                    }
                };
            }
            throw error;
        }
    };
};

export const updateUser = (updatedUser) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(`${API_URL}/user/${updatedUser._id}`, updatedUser);
            dispatch({ type: UPDATE_USER, payload: res.data });
            return res.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };
};

export const deleteUser = (user) => {
    return async (dispatch) => {
        try {
            if (user.logoPressAgency) {
                dispatch(await deleteElement(user.logoPressAgency));
            }
            await axios.delete(`${API_URL}/user/${user._id}`);
            dispatch({type: DELETE_USER, payload: user._id});
        } catch (err) {
            console.log(err);
        }
    };
};

export const forgotPassword = (email) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/user/forgot-password`, { email });
            return {
                payload: {
                    success: true,
                    message: response.data.message
                }
            };
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return {
                    payload: {
                        success: false,
                        message: "User not found"
                    }
                };
            }
            throw error;
        }
    };
};

export const resetPassword = (token, password) => async (dispatch) => {
    try {
        const response = await axios.put(`http://localhost:5000/user/reset-password/${token}`, { password });
        dispatch({ type: 'RESET_PASSWORD_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({ type: 'RESET_PASSWORD_FAILURE', payload: error.response.data });
        throw error;
    }
};

export const finalizeOrder = (userId, purchaseDetails) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/user/${userId}/finalize-order`, { purchaseDetails });
            dispatch({ type: 'FINALIZE_ORDER_SUCCESS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'FINALIZE_ORDER_FAILURE', payload: error.response.data });
            throw error;
        }
    };
};