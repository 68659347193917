import React, {useEffect, useState} from 'react';
import AdminNewMusic from "./AdminNewMusic";
import {pictureCompressor} from "../assets/utils/pictureCompressor";
import {resetReducer, setInterviewPhotoPartner, setLogoPartner} from "../actions/partnerUploads.action";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {setAudioGuidedTour} from "../actions/contentGuidedTour.action";
import {setAlbum} from "../actions/album.action";
import GenericSelect from "../components/GenericSelect";

const AdminNewAlbum = () => {
    const [formState, setFormState] = useState({
        isFormValid: false,
        valueCitySelector: [],
        logoNewAlbum: null,
        logoGroupe: null,
        title: '',
        description: '',
        costParticulier: '',
        costPro: '',
        musics: [{id: Date.now(), audioSrc: null}]
    });
    const [uploadedMusics, setUploadedMusics] = useState([]);
    const audioAlbum = useSelector((state) => state.contentGuidedTourReducer);
    const logoAlbum = useSelector((state) => state.partnerUploadsReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const dispatch = useDispatch();


    const handleLogoUpload = async (e, logoType) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setFormState((prevState) => ({
                ...prevState,
                [logoType]: {file, fileURL},
            }));
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormState((prevState) => ({...prevState, [name]: value}));
    };

    const handleMusicChange = (musics) => {
        setFormState((prevState) => ({...prevState, musics}));
    };

    const getContentCityByCityName = (cityName) => {
        const city = dataCity.find((city) => city.name === cityName);
        return city.contentCity;
    }

    useEffect(() => {
        const isFormValid = formState.title !== '' &&
            formState.description !== '' &&
            formState.valueCitySelector.length !== 0 &&
            formState.costParticulier !== '' &&
            formState.costPro !== '' &&
            formState.logoNewAlbum !== null &&
            formState.logoGroupe !== null &&
            !formState.musics.some(music => music.audioSrc === null) &&
            !formState.musics.some(music => music.title === '') &&
            !formState.musics.some(music => music.cost === '') &&
            !formState.musics.some(music => music.timePreview === '');

        if (formState.isFormValid !== isFormValid) {
            setFormState((prevState) => ({...prevState, isFormValid}));
        }
    }, [formState.title, formState.description, formState.costParticulier, formState.costPro, formState.logoNewAlbum, formState.logoGroupe, formState.musics]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formState.isFormValid) {
            alert("Veuillez remplir tous les champs obligatoires");
            return;
        }

        if (formState.logoNewAlbum) {
            const formData = await pictureCompressor(formState.logoNewAlbum.file);
            dispatch(await setLogoPartner(formData));
        }

        if (formState.logoGroupe) {
            const formData = await pictureCompressor(formState.logoGroupe.file);
            dispatch(await setInterviewPhotoPartner(formData));
        }

        const musicPromises = formState.musics.map(async (music) => {
            if (music.audioSrc) {
                const formData = new FormData();
                formData.append("file", music.audioSrc);
                return dispatch(setAudioGuidedTour(formData));
            }
        });

        await Promise.all(musicPromises);

    };

    useEffect(() => {
        if (!isEmpty(audioAlbum) && uploadedMusics.length !== formState.musics.length) {
            setUploadedMusics([...uploadedMusics, audioAlbum]);
        }
    }, [audioAlbum]);

    useEffect(() => {
        if (!isEmpty(logoAlbum.logo) && !isEmpty(logoAlbum.interviewPhoto) && uploadedMusics.length === formState.musics.length) {
            const albumData = {
                name: formState.title,
                description: formState.description,
                logoGroupe: logoAlbum.interviewPhoto,
                logoAlbum: logoAlbum.logo,
                content: formState.musics.map((music, index) => ({
                    title: music.title,
                    song: uploadedMusics[index],
                    cost: music.cost,
                    timePreview: music.timePreview
                })),
                cost: {
                    particulier: formState.costParticulier,
                    pro: formState.costPro
                }
            };
            console.log(albumData);
            let contentCityId = formState.valueCitySelector.map(city => getContentCityByCityName(city.value));
            dispatch(setAlbum(albumData, contentCityId));
            dispatch(resetReducer());
            resetAlbum();
        }
    }, [logoAlbum, audioAlbum, uploadedMusics]);

    const resetAlbum = () => {
        setFormState({
            valueCitySelector: [],
            logoNewAlbum: null,
            logoGroupe: null,
            title: '',
            description: '',
            costParticulier: '',
            costPro: '',
            musics: [{id: Date.now(), audioSrc: null}]
        });
        setUploadedMusics([]);
    };

    const handleChangeCitySelector = (selectedOptions) => {
        setFormState((prevState) => ({ ...prevState, valueCitySelector: selectedOptions }));
    };

    const transformCityOptions = (data) => {
        return data.map((city) => ({ label: city.name, value: city.name }));
    };

    return (
        <div>
            <h2>Création d'un nouvel album</h2>
            <form onSubmit={handleSubmit}>
                <div className="info-album">
                    <div>
                        <div className="label-input">
                            <label htmlFor="title">Titre de l'album</label>
                            <input type="text" id="title" name="title" value={formState.title}
                                   onChange={handleInputChange}/>
                        </div>
                        <div className="label-input">
                            <GenericSelect
                                onChange={handleChangeCitySelector}
                                value={formState.valueCitySelector}
                                selector="cityReducer"
                                optionsTransformer={transformCityOptions}
                                placeholder="Sélectionnez une ou plusieurs villes"
                                isMulti={true}
                                enableSelectAll={true}
                            />
                        </div>
                    </div>
                    <div className="label-input">
                        <label htmlFor="description">Description de l'album</label>
                        <textarea id="description" name="description" value={formState.description}
                                  onChange={handleInputChange}/>
                    </div>
                    <div className="cost-album">
                        <div className="label-input">
                            <label htmlFor="costParticulier">Coût de l'album particulier</label>
                            <input type="number" id="costParticulier" name="costParticulier"
                                   value={formState.costParticulier} onChange={handleInputChange}/>

                        </div>
                        <div className="label-input">
                            <label htmlFor="costPro">Coût de l'album professionnel</label>
                            <input type="number" id="costPro" name="costPro" value={formState.costPro}
                                   onChange={handleInputChange}/></div>
                    </div>
                </div>
                <div className="info-photo">
                    <div className="label-input">
                        <label htmlFor="logoNewAlbum" className="button-label">
                            Ajouter un logo pour l'album
                        </label>
                        <input type="file" id="logoNewAlbum" onChange={(e) => handleLogoUpload(e, 'logoNewAlbum')}
                               hidden/>
                        {formState.logoNewAlbum && (
                            <section className="uploaded-logo">
                                <h3>Logo album ajouté</h3>
                                <img src={formState.logoNewAlbum.fileURL} alt="Logo ajouté" width={200}/>
                            </section>
                        )}
                    </div>
                    <div className="label-input">
                        <label htmlFor="logoGroupe" className="button-label">
                            Ajouter un logo pour le groupe
                        </label>
                        <input type="file" id="logoGroupe" onChange={(e) => handleLogoUpload(e, 'logoGroupe')} hidden/>
                        {formState.logoGroupe && (
                            <section className="uploaded-logo">
                                <h3>Logo groupe ajouté</h3>
                                <img src={formState.logoGroupe.fileURL} alt="Logo ajouté" width={200}/>
                            </section>
                        )}
                    </div>
                </div>
                <section className="new-music">
                    <AdminNewMusic musics={formState.musics} onMusicChange={handleMusicChange}/>
                </section>
                <input
                    type="submit"
                    value="Créer l'album"
                    style={{
                        backgroundColor: formState.isFormValid ? "#e2b259" : "black",
                        color: formState.isFormValid ? "black" : "white",
                    }}
                />
            </form>
        </div>
    );
};

export default AdminNewAlbum;