import {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {hideFlashMessage} from '../actions/flashMessage.action';
import FlashMessage from './FlashMessage';
import {useNavigate, useLocation} from 'react-router-dom';

const FlashMessageContainer = ({flashMessage, hideFlashMessage}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [path, setPath] = useState(null);

    useEffect(() => {
        if (flashMessage && flashMessage.redirectPath) {
            navigate(flashMessage.redirectPath);
            setPath(flashMessage.redirectPath);
            dispatch(hideFlashMessage({redirectPath: null}));

        }
    }, [dispatch, flashMessage, navigate]);

    useEffect(() => {
        if (flashMessage && flashMessage.location && location.pathname !== flashMessage.location) {
            hideFlashMessage();
        }
        if (path && path !== location.pathname) {
            hideFlashMessage();
            setPath(null);
        }
    }, [location.pathname, path, hideFlashMessage]);

    if (!flashMessage) return null;

    return (
        <FlashMessage
            message={flashMessage.message}
            onClose={hideFlashMessage}
            backgroundColor={flashMessage.color}
        />
    );
};

export default connect(
    (state) => ({flashMessage: state.flashMessageReducer}),
    {hideFlashMessage}
)(FlashMessageContainer);