// src/components/PlayerBar.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';

const PlayerBar = ({ currentAudio, isPlaying, onPlayPause, onPrev, onNext }) => {

    useEffect(() => {
        if ('mediaSession' in navigator && currentAudio) {
            navigator.mediaSession.metadata = new window.MediaMetadata({
                title: currentAudio.title,
                artist: currentAudio.artist || 'Inconnu',
                album: currentAudio.album || '',
                artwork: [
                    { src: currentAudio.cover || '', sizes: '512x512', type: 'image/png' }
                ]
            });

            navigator.mediaSession.setActionHandler('play', () => onPlayPause());
            navigator.mediaSession.setActionHandler('pause', () => onPlayPause());
            navigator.mediaSession.setActionHandler('previoustrack', () => onPrev());
            navigator.mediaSession.setActionHandler('nexttrack', () => onNext());
        }
    }, [currentAudio, onPlayPause, onPrev, onNext]);

    if (!currentAudio) return null;

    return (
        <div className="player-bar">
            <div className="track-info">
                <p>{currentAudio.title}</p>
            </div>
            <div className="controls">
                <button onClick={onPrev}><FontAwesomeIcon icon={faBackward} /></button>
                <button onClick={onPlayPause}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </button>
                <button onClick={onNext}><FontAwesomeIcon icon={faForward} /></button>
            </div>
        </div>
    );
};

export default PlayerBar;
