import { useSelector } from "react-redux";

const Logo = () => {
    const currentCity = useSelector((state) => state.cityReducer);

    return (
        <div className="logo">
            <img
                src={
                    "https://api.lesbonsplans.pro" +
                    "/upload/city/image/" +
                    currentCity.logo
                }
                alt="Logo Les-Bons-Plans"
            />
        </div>
    );
};

export default Logo;