export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';


export const showFlashMessage = (message, color = 'green', duration = 5000, redirectPath = null) => (dispatch) => {
    let location = null;
    if(!redirectPath) {
        location =  window.location.pathname;
    }
    dispatch({
        type: SHOW_FLASH_MESSAGE,
        payload: { message, color, redirectPath, location }
    });

    if (duration) {
        setTimeout(() => dispatch(hideFlashMessage()), duration);
    }
};

export const hideFlashMessage = (payload = {}) => ({
    type: HIDE_FLASH_MESSAGE,
    payload
});